<script>

import PricingRub from "@/components/pricing_for_month_week_sub";

export default {
  props: {
    pricingDataMonth: {
      type: Array,
      default: [],
    },
    pricingDataYear: {
      type: Array,
      default: [],
    },

  },
  data() {
    return {};
  },
  components: {
    PricingRub,
  }
};
</script>

<template>

      <!-- Price Start -->

    <!--end row
          <Pricing1 :pricingData="pricingData" />
    -->

       <div class="container">



          <div class="row justify-content-center">
            <div class="col-12 text-center">
              <div class="section-title pb-2">
                <h5 class="title">Сколько стоит доступ?</h5>
                <!--<h2> ⇩ </h2> -->
               <!-- <p class="text-muted para-desc mb-0 mx-auto">
                  Вы можете пользоваться
                  <span class="text-primary font-weight-bold">Skillrank</span> беплатно, но с ограниченным функционалом.
                  Платный доступ снимет ограничения на просмотры резюме и рассылки.
                </p>-->
              </div>
            </div>
          </div>



         <!-- <div class="row align-items-center"> -->
          <!--<div class ="row align-items-center">
            <div class="col-12">
              <div class="text-center">-->
          <div class="row align-items-center">
            <div class="col-12">
                <PricingRub :pricingData="pricingDataMonth" />
               </div>

            </div>




              <!--<b-tabs

                  pills
                  justified
                  nav-class="justify-content-center d-inline-block border py-1 px-2 rounded-pill"
                > -->

                 <!-- <b-tab
                    title-item-class="d-inline-block"
                    title-link-class="px-3 rounded-pill"
                  >
                    <template v-slot:title> Месяц </template>
                    <PricingRub :pricingData="pricingDataMonth" />
                    <!--end row
                  </b-tab>-->
                 <!-- <b-tab
                    title-item-class="d-inline-block"
                    title-link-class="px-3 rounded-pill"
                  >
                    <template v-slot:title>
                      Год
                      <span class="badge badge-pill badge-success">25% скидка </span>
                    </template>
                    <PricingRub :pricingData="pricingDataYear" />

                  </b-tab> -->
               <!-- </b-tabs> -->
           <!--   </div>
            </div>

          </div>-->









          <!--end row-->

       </div>




        <!--end container-->
        <!-- Price End -->
</template>
