<script>

import store from "@/store/store";

export default {
  props: {
    pricingData: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {


      pay_in_sql: {
        user_email: '',
        user_id: 0,
        service_name: '',
        description: '',
        price: 0,
        quantity: 0,
      },

    };
  },
  methods: {
    goToTinkov(title,price,quantity,period) {

      this.pay_in_sql.user_email = store.state.username;
      this.pay_in_sql.user_id = store.state.user_id;
      this.pay_in_sql.price = price*100;
      this.pay_in_sql.quantity = quantity;
      //let x = (quantity===1) ? 'на 3 дня' : 'на 7 дней'
      //this.pay_in_sql.service_name = title + ' Skillrank ' + x;
      //this.pay_in_sql.description = 'service_'+price+'_'+quantity;
      //this.pay_in_sql.description = title + ' Skillrank ' + x;
      this.pay_in_sql.service_name = title + ' Skillrank ' + period;
      this.pay_in_sql.description = title + ' Skillrank ' + period;
      console.log(this.pay_in_sql);
      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/payment/init?access_token=' + store.state.token,
        data: this.pay_in_sql,
      }).then((res) => {
        console.log(res.data)
        window.open(res.data, '_blank');
      });

    }
  }
};
</script>

<template>
  <div class="row">
    <div v-if="$store.state.isLoggedIn===0"
      class="col-12 mt-4 pt-2"
      v-for="(item, index) of pricingData"
      :key="index"
    >


      <div
        class="card pricing-rates business-rate shadow bg-light rounded text-center border-0"
      >
        <div
          class="ribbon ribbon-right ribbon-warning overflow-hidden"
          v-if="item.isBest === true"
        >
          <span class="text-center d-block shadow small h6">Лучший</span>
        </div>

        <div class="card-body py-5">
          <h6 class="title font-weight-bold text-uppercase text-primary mb-4">
            {{ item.title }}
          </h6>
          <div class="d-flex justify-content-center mb-4">
            <span class="h4 mb-0 mt-2">₽</span>
            <span class="price h1 mb-0">{{ item.price }}</span>
            <span class="h4 align-self-end mb-1"> / {{ item.period }}</span>
          </div>

          <ul class="list-unstyled mb-0 pl-0">
            <li
              class="h6 text-muted mb-0"
              v-for="(feature, index) in item.feature"
              :key="index"
            >
              <span class="text-primary h5 mr-2"
                ><i class="uil uil-check-circle align-middle"></i></span
              >{{ feature }}
            </li>
          </ul>
          <a href="/login" class="btn btn-primary mt-4">{{
            item.button
          }}</a>
        </div>
      </div>

    </div>

    <div v-if="$store.state.isLoggedIn===1"
        class="col-12 mt-4 pt-2"
        v-for="(item, index) of pricingData"
        :key="index"
    >
      <div
          class="card pricing-rates business-rate shadow bg-light rounded text-center border-info"
          v-if="item.isBest === true"
      >


        <div class="card-body bg-soft-info py-5">
          <h6 class="title font-weight-bold text-uppercase text-primary mb-4">
            {{ item.title }}
          </h6>
          <div class="d-flex justify-content-center mb-4">
            <span class="h4 mb-0 mt-2">₽</span>
            <span class="price h1 mb-0">{{ item.price }}</span>
            <!--<span class="h4 align-self-end mb-1"> / {{ item.period }}</span>-->
          </div>

          <ul class="list-unstyled mb-0 pl-0">
            <li
                class="h6 text-muted mb-0"
                v-for="(feature, index) in item.feature"
                :key="index"
            >
              <span class="text-primary h5 mr-2"
              ><i class="uil uil-check-circle align-middle"></i></span
              >{{ feature }}
            </li>
          </ul>
          <h5 > <br>Выбран сейчас</h5>
        </div>
      </div>

      <div
          class="card pricing-rates business-rate shadow bg-light rounded text-center border-0"
          v-else
      >


        <div class="card-body py-5">
          <h6 class="title font-weight-bold text-uppercase text-primary mb-4">
            {{ item.title }}
          </h6>
          <div class="d-flex justify-content-center mb-4">
            <span class="h2 mb-0 mt-2">₽</span>
            <!--<span class="price h1 mb-0">{{ item.price }}</span> -->
            <span class="h2 align-self-end mb-1"> {{ item.price }} / {{ item.period }}</span>
          </div>

          <ul class="list-unstyled mb-0 pl-0">
            <li
                class="h6 text-muted mb-0"
                v-for="(feature, index) in item.feature"
                :key="index"
            >
              <span class="text-primary h5 mr-2"
              ><i class="uil uil-check-circle align-middle"></i></span
              >{{ feature }}
            </li>
          </ul>

          <a @click="goToTinkov(item.title,item.price,item.quantity,item.period)" class="btn btn-primary mt-4">{{
              item.button
            }}</a>
        </div>
      </div>




    </div>
  </div>
</template>
