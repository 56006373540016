<script>
import { VueTyper } from "vue-typer";
import {
  ArrowUpIcon,
  PlayIcon,
  HomeIcon,
  UserIcon,
  UserCheckIcon,
  MailIcon,
  KeyIcon,
  CpuIcon,
  VideoIcon, ShieldIcon,
} from "vue-feather-icons";
import countTo from "vue-count-to";
import { Carousel, Slide } from "vue-carousel";
import Navbar from "@/components/navbar0";

import Footer from "@/components/footer";
//import Pricing2 from "@/components/pricing2";
import PricingNew from "@/components/pricing_recurrent_parent";
import PricingMonth from "@/components/pricing_rub_subscription_month";
import PricingWeek from "@/components/pricing_rub_subscription_week";
import PricingMonthWeek from "@/components/pricing_rub_subscription_month_week";
import News from "@/components/news";
import store from "@/store/store";


/**
* hello world
*/

/**
 * Index-business component
 */
export default {
  data() {
    return {


      input2sql:{
        first_name: '',
        last_name: '',
        username: '',
        password: '',
        termsAgree: false
      },

      token_habr: '4c580965dd0b354fb6f821adf963a29c9d0ce77d66af0f88df3b0506f5005094',
      redirect_habr: 'https://skillrank.ru/api/prod/loginHabr',

      clinet_id_hh: 'LCEDG0N75IFPHNMD3A4LP7K3MLAC1PRPK9HG7A5M3NJG7JFQEEM29R3GQICRQ3RO',



      featuresData: [
        {
          icon: "uil uil-globe",
          title: "База резюме",
          description:
            "Собираем детали резюме кандидатов со всех основных площадок Github, LinkedIn, Habr и др.",
        },
        {
          icon: "uil uil-fast-mail",
          title: "Связь с кандидатами",
          description:
            "Расскажите о своей вакансии кандидатам напярмую через Skillrank",
        },
        {
          icon: "uil uil-analysis",
          title: "Аналитика открытий писем",
          description:
            "Мы предоставляем всю необходимую информацию: открытия, количество просмотров, а также кликов по ссылке в вашем шаблоне",
        },
        {
          icon: "uil uil-file-search-alt",
          title: "Поиск по базе",
          description:
            "Наш AI понимает запросы в поисковой строке на естественном языке.",
        },
        {
          icon: "uil uil-sitemap",
          title: "Интеграции c рекрутинговыми системами",
          description:
            "Мы настроили интеграцию резюме с Huntflow, Talantix, Potok, Friend.Work, Zoho Recriut, e-Staff, CleverStaff, чтобы экспорт резюме в вашу ATS был удобен. ",
        },
        {
          icon: "uil uil-calendar-alt",
          title: "Отчеты по воронке",
          description:
            "Можно сформировать отчеты по конверсиям и воронке к вакасниям.",
        },

      ],
      pricingDataMonth: [



        {
          title: "Подписка на платформу",
          price: 0,
          quantity: 1,
          period: "месяц",
          feature: [
            "Первая неделя бесплатно",
            //"Первый день бесплатно, затем 1990р в месяц",
            // "Неограниченные рассылки",
            // "Аналитика по рассылкам",
            //"Консультации рекрутера",
          ],
          button: "Купить",
          isBest: false,
        },
      ],

      pricingDataWeek: [

        {
          title: "Подписка на платформу",
          price: 690,
          quantity: 1,
          period: "неделя",
          feature: [
            "Первые 3 дня бесплатно, затем 690р в неделю",
            // "Неограниченные рассылки",
            // "Аналитика по рассылкам",
            //"Консультации рекрутера",
          ],
          button: "Купить",
          isBest: false,
        },


    ],
        pricingDataMonthWeek: [

      {
        title: "Подписка на платформу",
        price: 690,
        quantity: 1,
        period: "неделя",
        feature: [
          "Первые 3 дня бесплатно, затем 690р в неделю",
          // "Неограниченные рассылки",
          // "Аналитика по рассылкам",
          //"Консультации рекрутера",
        ],
        button: "Купить",
        isBest: false,
      },

      {
        title: "Подписка на платформу",
        price: 1990,
        quantity: 1,
        period: "месяц",
        feature: [
          "Первые 3 дня бесплатно, затем 1990р в месяц",
          // "Неограниченные рассылки",
          // "Аналитика по рассылкам",
          //"Консультации рекрутера",
        ],
        button: "Купить",
        isBest: false,
      },
            ],

     // pricingDataMonth: [
      //  {
      //    title: "Пробный дневной доступ сразу после регистрации",
      //    price: 0,
      //    period: "1 день",
      //    feature: [
            //"Полный доступ к базе резюме",
            //"Неограниченные рассылки",
            //"Аналитика по рассылке",
     //     ],
     //     button: "Получить",
     //     isBest: false,
     //   },
      //  {
      //    title: "Полный 3-х дневный доступ к Платформе",
      //    price: 250,
      //    quantity: 1,
      //    period: "3 дня",
      //    feature: [
            // "Полный доступ к базе резюме",
            // "Неограниченные рассылки",
            // "Аналитика по рассылкам",
            //"Консультации рекрутера",
       //   ],
       //   button: "Купить",
       //   isBest: false,
       // },
       // {
       //   title: "Недельный доступ к Платформе и интеграция",
      //    price: 400,
      //    quantity: 1,
       //   period: "7 дней",
       //   feature: [
            //"Полный доступ к базе резюме",
            //"Неограниченные рассылки",
            //"Аналитика по рассылкам",
            //"Консультации рекрутера",
            // "Интеграция с ATS или CRM"
        //  ],
       //   button: "Купить",
       //   isBest: false,
      //  },
     // ],
      pricingDataYear: [
        {
          title: "Пробный доступ",
          price: 0,
          period: "мес",
          feature: [
            "10 просмотров резюме",
            "5 писем рассылки",
            "Аналитика по рассылке",
          ],
          button: "Получить",
          isBest: true,
        },
        {
          title: "Полный Доcтуп к Платформе",
          price: 5990,
          quantity: 12,
          period: "мес",
          feature: [
            "Полный доступ к базе резюме",
            "Неограниченные рассылки",
            "Аналитика по рассылкам",
            "Консультации рекрутера",
          ],
          button: "Купить",
          isBest: false,
        },
        {
          title: "Доcтуп и интеграция",
          price: 7490,
          quantity: 12,
          period: "мес",
          feature: [
            "Полный доступ к базе резюме",
            "Неограниченные рассылки",
            "Аналитика по рассылкам",
            "Консультации рекрутера",
            "Интеграция с ATS или CRM"
          ],
          button: "Купить",
          isBest: false,
        },
      ],

      logData: {
        visitor_uid: 0,
        user_id: 0,
        url: '',
        page_root: '',
      }
    };
  },

  methods: {



    signUpFunction(){

      /*
      const loading = this.$vs.loading({
        target: this.$refs.signInButton,
        scale: '0.6',
        background: '#00967d',
        opacity: 0.5,
        color: '#fff'
      });
        */

      this.$vs.loading({
        container: this.$refs.signInButton.$el,
        scale: '0.6',
        background: '#00967d',
        opacity: 0.5,
        color: '#fff',
      });

      this.axios({
        method:'post',
        url: '' + this.$hostname +'/register',
        data: this.input2sql,
      }).then( (res) => {

            if (res.data.status === 'success'){
              //loading.close();
              this.$vs.loading.close("#div-with-loading > .con-vs-loading");

              store.commit('Update_justRegistered_State', true);

              this.$cookies.set('access_token_skillrank',res.data.access_token);
              this.$cookies.set('username_skillrank',res.data.username);
              this.$cookies.set('authority_skillrank',res.data.authority);
              this.$cookies.set('confirmed_skillrank',res.data.confirmed);
              this.$cookies.set('id_skillrank',res.data.id);
              this.$cookies.set('showAccountConfirmAlert','yes');

              store.commit('UPDATE_LOGGEDIN_STATE', 1);
              store.commit('UPDATE_TOKEN', res.data.access_token);
              store.commit('UPDATE_USERNAME', res.data.username);
              store.commit('UPDATE_USER_ID', res.data.id);
              store.commit('UPDATE_USER_AUTHORITY', res.data.authority);

              this.justRegistered = true;

              this.$router.push({path:'/search-candidates'});


            } else {
              this.statusFlagRegistration = true
              //loading.close();
              this.$vs.loading.close("#div-with-loading > .con-vs-loading");
              //this.$router.push({path:'/'})

              this.$vs.notify({
                time: 4000,
                title: 'Ошибка',
                text: res.data.status,
                color: 'danger'
              });

            }
          }
      ).catch((err) => {
        this.statusFlagRegistration = true
        console.error(err);
        //loading.close();
        this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        this.$router.push({path:'/signup'})

        this.$vs.notify({
          time: 4000,
          title: 'Ошибка',
          text: res.data.status,
          color: 'danger'
        });

      });




    },



    loginHabr(){

      var link = 'https://career.habr.com/integrations/oauth/authorize?client_id='+this.token_habr
      link = link +'&redirect_uri='
      link = link +this.redirect_habr
      link = link +'&response_type=code'
      window.open(link);
    },

    loginHh(){


      var link = 'https://hh.ru/oauth/authorize?response_type=code&client_id='+this.clinet_id_hh

      window.open(link);
    },




    openNotification() {
      this.$vs.notification({
        color: 'dark',
        duration: 'none',
        title: 'Skillrank использует Cookies',
        text: `для персонализации сервисов. Запретить обработку Cookies можно в настройках Вашего браузера.
        Узнать, какие данные и как мы используем, можно в нашей <a href=\"/privacy\">политике конфиденциальности</a>.`
      })
    },
    sayHello(){
      console.log('inside say hello');
      this.axios({
        method:'get',
        url: this.$hostname + '/hello'
      }).then( (res) => {

      });

    }
  },
  /*
    created() {
      window.addEventListener('beforeunload', this.sayHello)
    },

    beforeDestroy() {
      window.removeEventListener('beforeunload', this.sayHello)
    },

    beforeRouteLeave (to, from, next) {

      console.log('inside beforeRouteLeave');
      this.axios({
        method:'get',
        url: this.$hostname + '/hello'
      }).then( (res) => {

      });

    },
  */

  mounted() {


    /*
    let ip_addr = '0.0.0.0';

    this.axios({
      method: 'get',
      url: 'https://api.ipify.org/?format=json',
    }).then( (res) => {
      let ip_addr = res.data.ip
      console.log(' ip = ' + ip_addr)
    } );


    if (this.$cookies.isKey("skillrank_visitor")) {

      let visitor_uid = this.$cookies.get("skillrank_visitor","1", Infinity);
      console.log(' visitor_uid ' + visitor_uid);

      if (this.$cookies.isKey("id_skillrank")) {

      }

      this.$axios({
        method: 'post',
        url: '' + this.$hostname + '/log/event',
        data: this.logData,
      }).then(() => {});

    } else {
      this.openNotification();


      this.$axios({
        method: 'get',
        url: '' + this.$hostname + '/log/get-new-user-uid',
      }).then((res) => {
        this.$cookies.set("skillrank_visitor", "1", Infinity);

        this.$axios({
          method: 'post',
          url: '' + this.$hostname + '/log/event',
          data: this.logData,
        }).then(() => {});

      });


    }
    */




    let utmQuery = decodeURIComponent(window.location.href);
    console.log(decodeURIComponent(window.location.href));
    console.log(decodeURIComponent(window.location.pathname));


  },

  components: {
    Navbar,

    Footer,
    ArrowUpIcon, PlayIcon,     HomeIcon, UserIcon, UserCheckIcon, MailIcon, KeyIcon,
    "vue-typer": VueTyper,
    countTo,
    PricingNew,
    PricingMonth,
    PricingWeek,
    PricingMonthWeek,
    News,
    Carousel,
    Slide,
    ShieldIcon,
    CpuIcon,
    VideoIcon
  },
};
</script>

<template>


  <div>
    <Navbar />




    <section class="bg-half-170 border-bottom w-100" id="home">
        <!--style="background: url('images/enterprise.png') center center"-->

     <!-- <div class="bg-overlay"></div>-->



      <div class="container">

       <!-- <div class="row align-items-center">
          <div class="text-center">
          <div class="col-lg-12 col-md-12">
            <div class="title-heading mt-4">
              <h1 class="heading mb-3">
                Быстро <br />
                Эффективно <br />
                Дёшево
              </h1>
              <p class=" text-muted">

                Найдите подходящего кандидата с помощью умного поиска по базе, состоящей из 350.000 резюме,
                или начните собственную кампанию массовых рассылок
              </p> -->
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-12 text-center mt-0 mt-md-1 pt-0 pt-md-5">
              <div class="title-heading margin-top-50">
                <h1 class="heading mb-3">Поиск по базе резюме, рассылка <br> писем кандидатам, публикация вакансий</h1>
                <p class="para-desc mx-auto text-muted">
                  Найдите подходящего кандидата с помощью умного поиска по базе, состоящей из 350.000 резюме,
                  или начните собственную кампанию массовых рассылок
                </p>
                <div class="mt-4 pt-2">
                  <div v-if="$store.state.isLoggedIn===0">

                    <!-- <vs-button id="div-with-loading" ref="signInButton" size="xl" type="button" class="btn btn-primary btn-block vs-con-loading__container"
                                @click="signUpFunction">
                       Найти сотрудника
                     </vs-button>-->
                    <a href="/signup" class="btn btn-primary btn-block" size="xl">
                      Найти сотрудника
                    </a>
                  </div>
                  <div v-if="$store.state.isLoggedIn===1">
                    <a href="/vector-search" size="xl" class="btn btn-primary btn-block ">
                      Найти сотрудника
                    </a>
                  </div>
                </div>
              </div>
            </div>
         <!-- </div> -->





            <!--  <div class="mt-4 pt-2">
                <div v-if="$store.state.isLoggedIn===0">

                <vs-button id="div-with-loading" ref="signInButton" size="xl" type="button" class="btn btn-primary btn-block vs-con-loading__container"
                           @click="signUpFunction">
                  Найти сотрудника
                </vs-button>--
                  <a href="/signup" class="btn btn-primary btn-block vs-con-loading__container" size="xl">
                    Найти сотрудника
                  </a>
                  </div>
                <div v-else>
                  <vs-button id="div-with-loading" href="/search-candidates" size="xl" type="button" class="btn btn-primary btn-block vs-con-loading__container">
                    Найти сотрудника
                  </vs-button>
                  </div>
              </div> -->
            </div>
          </div>


    <!-- <div class="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="card shadow rounded border-0 ml-lg-5"> -->
         <!-- <div class="card-body">
            <h5 class="card-title">Будет интересно</h5> -->

            <!--  <div class="row"> -->

              <!--  <div class="col-md-13">
                  <div>


                    <div class="position-relative">

                      <carousel
                          id="owl-fade"
                          class="owl-carousel owl-theme"
                          dir="ltr"
                          :per-page="1"
                          :autoplay="true"
                          :loop="true"
                      >

                        <Slide>
                          <div>



                                <div class="p-2">
                                    <div


                                        v-b-modal.LoginForm
                                        data-toggle="modal"
                                        data-terget="LoginForm"
                                        class="btn btn-soft-primary"
                                    >
                                    Что такое Skillrank.ru?</div>

                                </div>
                            <b-modal id="LoginForm" title="Что такое Skillrank.ru?" centered>
                                <div class="bg-white p-2 rounded box-shadow">
                                  <p class="text-muted mb-0">
                                      Рекрутинговая платформа Skillrank.ru - это относительно новое решение, которое быстро завоевало популярность благодаря своему уникальному подходу к поиску талантов. Система собирает резюме со всех доступных платформ и дает работодателям возможность ранжировать их в соответствии с их собственными предпочтениями. Таким образом, работодателям не придется тратить часы на поиск квалифицированных кандидатов в социальных сетях и на досках объявлений о вакансиях, а также тратить большие суммы денег на агентства по подбору персонала. Более того, он предоставляет широкий спектр данных о каждом кандидате.
                                      Это делает skillrank.ru одна из самых эффективных и экономичных рекрутинговых платформ в мире, что довольно четко отражается на ее популярности среди российских компаний.
                                    </p>
                                  </div>
                                </b-modal>
                          </div>


                        </Slide>
                        <Slide>
                          <div>





                              <div class="p-2">
                                <div

                                    v-b-modal.LoginForm2
                                    data-toggle="modal2"
                                    data-target="#LoginForm2"
                                    class="btn btn-soft-primary"
                                >
                                  А вы часто забываете об этом?</div
                                >
                              </div>
                            <b-modal id="LoginForm2" title="А вы часто забываете об этом?" centered>
                                <div class="bg-white p-2 rounded box-shadow">
                                  <p class="text-muted mb-0">
                                    Хотя при найме высококлассного сотрудника важно учитывать ставку заработной платы, важны и другие факторы. К ним относятся расположение офиса, набор технологий, которые вы будете использовать для развития, и то, что ваша компания может предложить им с точки зрения карьерного роста и бонусов. Таким образом, и вы, как работодатель, и они, как кандидат, сэкономите время, которое в наши дни является ценным ресурсом.
                                  </p>
                                </div>
                              </b-modal>
                          </div>


                        </Slide>

                        <Slide>
                          <div>




                              <div class="p-2">
                                <div

                                    v-b-modal.LoginForm3
                                    data-toggle="modal3"
                                    data-target="#LoginForm3"
                                    class="btn btn-soft-primary"
                                >
                                  Как мы формируем нашу базу резюме?</div
                                >
                              </div>
                            <b-modal id="LoginForm3" title="Как мы формируем нашу базу резюме?" centered>
                                <div class="bg-white p-2 rounded box-shadow">
                                  <p class="text-muted mb-0">
                                    Skillrank.ru объединяет резюме из разных источников, в том числе HH.ru , SuperJob.ru , LinkedIn, Хабр и более 70 других сайтов. Так что теперь искать работу стало намного проще: вам не нужно использовать несколько сайтов, чтобы найти лучшие возможности — вы можете просто использовать только Skillrank.ru !

                                    Кроме того, мы предоставляем вам доступ к крупнейшей базе резюме в России, чтобы вы могли легко найти наиболее подходящих кандидатов для вашей компании или для вашего следующего карьерного роста.

                                    Начать легко — просто зайдите на наш веб-сайт и начните знакомство уже сегодня!
                                  </p>
                                </div>
                              </b-modal>
                          </div>


                        </Slide>

                        <Slide>
                          <div>



                            <div class="component-wrapper rounded shadow">
                              <div class="p-2">
                                <div

                                    v-b-modal.LoginForm4
                                    data-toggle="modal4"
                                    data-target="#LoginForm4"
                                    class="btn btn-soft-primary"
                                >
                                  Как увеличить эффективность подбора?</div
                                >
                              </div>
                            <b-modal id="LoginForm4" title="Как увеличить эффективность подбора?" centered>
                                <div class="bg-white p-2 rounded box-shadow">
                                  <p class="text-muted mb-0">
                                    Вы хотите преуспеть в подборе персонала? Ты не одинок.

                                    Проблема в том, что методы, которые вы использовали — поиск кандидатов в LinkedIn и размещение объявлений на сайтах вакансий — становятся все менее и менее эффективными по мере того, как все больше компаний используют их, делая кадровый резерв более конкурентоспособным. Это означает, что вам нужно найти другие способы привлечения кандидатов, что может быть действительно непросто.

                                    Вот тут-то мы и вступаем в игру. Мы уверены, что вы получите ответ от вашего кандидата в течение 24 часов, когда напишете ему с помощью нашей системы. Наша функция масштабной кампании по электронной почте дает вам возможность охватить сотни или тысячи потенциальных кандидатов одновременно, просто заполнив свою воронку кандидатов, создав сообщение с первого прикосновения и нажав "отправить". Это быстро и эффективно, потому что ни у кого нет времени, чтобы тратить его впустую!
                                  </p>
                                </div>
                              </b-modal>
                          </div>
                          </div>

                        </Slide>


                      </carousel>
                     <div v-if="$store.state.isLoggedIn===0">
                        <a href="/login" class="btn btn-soft-primary"> Primary </a>
                      </div>
                      <div v-else>

                      <a href="" class="btn btn-soft-primary"> Primary </a>
                        </div>--

                    </div>

                  </div>

                </div> -->

               <!-- <div class="col-md-12">
                  <div class="form-group">
                    <div class="position-relative">

                      <div v-if="$store.state.isLoggedIn===0">
                        <a href="/instruction" class="btn btn-soft-primary"> Как мы работаем? </a>
                      </div>
                      <div v-else>

                        <a href="/instruction" class="btn btn-soft-primary"> Как мы работаем? </a>
                      </div>
                      </div>
                    </div>
                  </div> -->


                 <!--   <div class="col-md-12">
                      <div class="form-group">
                        <div class="position-relative">

                          <div v-if="$store.state.isLoggedIn===0">
                            <a href="/buy" class="btn btn-soft-primary"> Сколько стоит доступ? </a>
                          </div>
                          <div v-else>

                            <a href="/buy" class="btn btn-soft-primary"> Сколько стоит доступ? </a>
                          </div>
                          </div> -->


                  <!--    </div>
                    </div>
                  </div>


          </div> -->
      <!--  </div>
      </div> -->
        </div>
     <!-- </div>
      </div> -->



   <!-- </section> -->


   <!-- <sectionn>
      <div class="container">
        <div class="row align-items-lg-center">
          <div class="col">
            <h1>w</h1>
          </div>
          <div class="col">
            <h1>w</h1>
          </div>
        </div>
      </div>
    </sectionn> -->


                <!--end col-->

    <!--<section> -->

   <!--   <div>
        <div class="col mt-4 pt-2" id="checkboxes">
            <div class="p-4">
              <div class="form-check form-check-inline">
                <div class="form-group mb-0">
                  <div class="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        class="custom-control-input"
                        id="customCheck1"
                    />
                    <label class="custom-control-label" for="customCheck1"
                    >Я даю согласие на</label
                    >
                  </div>

              </div>
              </div>
            </div>
          </div>
      </div> -->







      <div class="container mt-5">
        <div class="row">
          <div class="col margin-top-100">

        <div class="position-relative">
          <!--УСЛОВИЕ-->

         <!-- <div class="col">
            <div v-if="$user.state.test==1">
              <PricingMonth :pricingDataMonth="pricingDataWeek" />
            </div>

            <div v-if="$user.state.test==2">
              <PricingMonth :pricingDataMonth="pricingDataMonth" />
            </div>

            <div v-if="$user.state.test==3">
              <PricingMonth :pricingDataMonth="pricingDataMonthWeek" />
            </div> -->


        <!--    <div class="col">
              <div v-if="$store.state.isLoggedIn===0">
                <PricingMonth :pricingDataMonth="pricingDataWeek" />
              </div>

              <div v-if="$store.state.isLoggedIn===1">
                <PricingMonth :pricingDataMonth="pricingDataMonth" />
              </div>

              <div v-if="$store.state.isLoggedIn===1">
                <PricingMonth :pricingDataMonth="pricingDataMonthWeek" />
              </div> -->





            <!--

           <PricingMonth :pricingDataMonth="pricingDataMonth" :pricingDataYear="pricingDataYear" /> -->

          <!--  <PricingMonth :pricingDataMonth="pricingDataMonth" /> -->







            <PricingNew :pricingDataMonth="pricingDataMonth"  />

      </div>
        </div>
        </div>
      </div>
       <!--   </div>
        </div> -->







      <!--end container-->
    </section>





    <!-- Hero Start -->


              <!--
              <div class="alert alert-light alert-pills shadow" role="alert">
                <span class="badge badge-pill badge-danger mr-1">v2.5</span>
                <span class="content">
                  Build <span class="text-primary">anything</span> you want -
                  Landrick.</span
                >
              </div>
              -->
              <!--<h1 class="heading mb-1">
                Умный агрегатор IT вакансий из LinkedIn, CareerHabr и HH
              </h1>-->





              <!--<h5>
                Один день доступа к базе стоит от <span class="text-primary font-weight-bold">57 рублей</span>
              </h5>-->














          <!--end col-->

          <!--<div class="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
            <h1>dwadwadw</h1>
            </div>-->

          
           <!-- <div class="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div v-if="$store.state.isLoggedIn===0">
            <div class="card shadow rounded border-0 ml-lg-4">
              
              <--<div v-if="$store.state.isLoggedIn===0"> --
              <div class="card-body">
                <h5 class="card-title text-center"><span class="text-primary font-weight-bold">Зарегистрируйтесь</span> и получите <span class="text-primary font-weight-bold">пробный доступ</span> к базе</h5>

                <form class="login-form mt-4">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label
                          >Имя <span class="text-danger">*</span></label
                        >
                        <div class="position-relative">
                          <user-icon class="fea icon-sm icons"></user-icon>
                          <input
                            type="text"
                            class="form-control pl-5"
                            placeholder="Ваше имя"
                            name="s"
                            required=""
                            v-model="input2sql.first_name"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label
                          >Почта <span class="text-danger">*</span></label
                        >
                        <div class="position-relative">
                          <mail-icon class="fea icon-sm icons"></mail-icon>
                          <input
                            type="email"
                            class="form-control pl-5"
                            placeholder="Email"
                            name="email"
                            required=""
                            v-model="input2sql.username"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label
                          >Пароль <span class="text-danger">*</span></label
                        >
                        <div class="position-relative">
                          <key-icon class="fea icon-sm icons"></key-icon>
                          <input
                            type="password"
                            class="form-control pl-5"
                            placeholder="Пароль"
                            required=""
                            v-model="input2sql.password"
                          />
                          
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="custom-control custom-checkbox">

                          <input
                              type="checkbox"
                              class="custom-control-input"
                              id="customCheck1"
                              v-model="input2sql.termsAgree"
                          />

                          <label class="custom-control-label" for="customCheck1">
                            Нажимая кнопку «Зарегистрироваться», вы принимаете условия
                            <a href="/terms" class="text-primary">Правил</a> и
                            <a href="/license" class="text-primary">Соглашение</a> об использовании сайта.
                          </label>

                          <--<label class="custom-control-label">
                            Нажимая кнопку «Зарегистрироваться», вы принимаете условия
                            <a href="/terms" class="text-primary">Правил</a> и
                            <a href="/license" class="text-primary">Соглашение</a> об использовании сайта.
                          </label>
                            --
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <!-
                      <button class="btn btn-primary btn-block">
                        Зарегистрироваться
                      </button>
                      --
                      <vs-button id="div-with-loading" ref="signInButton" size="xl" type="button" class="btn btn-primary btn-block vs-con-loading__container"
                                 @click="signUpFunction">
                        Зарегистрироваться
                      </vs-button>

                    </div>
                    <div class="col-lg-12 mt-4 text-center">
                      <h6>Войдите с помощью учетной записи в других сервисах:</h6>
                      <div class="row">
                        <div class="col-6 mt-3">
                          <a
                              class="btn btn-block btn-light"
                              @click="loginHh"
                          >
                            <img src="images/login/min-hh-red.png" width="20" height="20" >
                            <!--<i class="mdi mdi-facebook text-primary"></i>--
                            HH</a>
                        </div>
                        !--end col-

                        <div class="col-6 mt-3">
                          <a
                              class="btn btn-block btn-light"
                              @click="loginHabr"
                          ><img src="images/login/careerHabrLogo1.jpg" width="20" height="20" >
                            Career.Habr</a
                          >
                        </div>
                        <!--end col-
                      </div>
                      --end icon-
                    </div>

                    <div class="mx-auto">
                      <p class="mb-0 mt-3">
                        <small class="text-dark mr-2"
                        >У вас уже есть аккаунт ?</small
                        >
                        <router-link
                            to="/login"
                            class="text-dark font-weight-bold"
                        >Войти</router-link
                        >
                      </p>
                    </div>

                  </div>
                </form>
              </div>
              
            </div>
            
          </div> -->
          <!--<div v-else>
              <br>
              <div class="col-lg-12 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
            <div class="position-relative">
            <img
              src="images/illustrator/working_with_computer.svg"
              class="rounded img-fluid mx-auto d-block"
              alt=""
            />
            </div>
          </div>
           </div>
          </div>
          <!--end col-->

        <!--end row-->

      <!--end container-->


    
    
  
















    <!--end section-->
    <!-- Hero End -->

    <!-- Feature Start -->
    <section class="section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">Что мы делаем ?</h4>
              <p class="text-muted para-desc mx-auto mb-0">
                Начните поиск сотрудников с
                <span class="text-primary font-weight-bold">Skillrank</span>, который обеспечит все, что нужно
                для закрытия вакансии: воронку кандидатов, инструмент для связи с соискателями, базу для хранения резюме,
                возможность публикации вакансии.

              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->


        <div class="row">
          <div
            class="col-md-4 col-12"
            v-for="(item, index) of featuresData"
            :key="index"
          >
            <div class="features mt-5">
              <div class="image position-relative d-inline-block">
                <i :class="`${item.icon} h1 text-primary`"></i>
              </div>

              <div class="content mt-4">
                <h5>{{ item.title }}</h5>
                <p class="text-muted mb-0">{{ item.description }}</p>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- End feature -->


    <!-- Price Start -->
    <!--
          <div class="container">

            <div class="row justify-content-center">
              <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                  <h4 class="title mb-4">Сколько стоит доступ?</h4>
                  <p class="text-muted para-desc mb-0 mx-auto">
                    Доступ в
                    <span class="text-primary font-weight-bold">Skillrank</span> дает
                    возможность.
                  </p>
                </div>
              </div>
            </div>
            end row
            <Pricing :pricingDataMonth="pricingDataMonth" :pricingDataYear="pricingDataYear" />
          </div>-->
      <!--end container-->
      <!-- Price End -->

      <!-- Counter End -->

    <div class="container mt-5">



        <ul class="list-unstyled text-lg-left mb-0">
          <li class="list-inline-item ml-1">
            <img
                src="images/payments/master-card.png"
                class="avatar avatar-ex-sm"
                title="Master Card"
                alt=""
            />
          </li>
          <li class="list-inline-item ml-1">
            <img
                src="images/payments/visa.png"
                class="avatar avatar-ex-sm"
                title="Visa"
                alt=""
            />
          </li>
          <li class="list-inline-item ml-1"> - <span class="text-muted para-desc">Вы можете оплатить доступ банковскими картами <b> MasterCard</b> и <b>Visa</b>.</span>
          </li>
        </ul>


    </div>

<section class="section">
      <div class="container mt-10 mt-6">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="section-title">
              <h4 class="title mb-4">
                Почему подбор с
                <span class="text-primary">Skillrank</span>
                будет успешен?
              </h4>
              <p class="text-muted para-desc">
                Мы фокусиремся на двух факторах успешного подбора:
                <span class="text-primary font-weight-bold">полнота</span> базы резюме потенциальных кандидатов и
                <span class="text-primary font-weight-bold">скорость</span> реакции на появление новых релевантных кандидатов.
              </p>
              <ul class="list-unstyled text-muted">
                <li class="mb-0">
                  <span class="text-primary h5 mr-2"><i class="uil uil-check-circle align-middle"></i></span>
                  Ежедневно обновляемая база резюме
                </li>
                <li class="mb-0">
                  <span class="text-primary h5 mr-2"><i class="uil uil-check-circle align-middle"></i></span>
                  Рассылка по релевантным активным кандидатам
                </li>
                <li class="mb-0">
                  <span class="text-primary h5 mr-2"><i class="uil uil-check-circle align-middle"></i></span>
                  Аналитика и инструменты управления конверсиями
                </li>
              </ul>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-6">
            <div class="row ml-lg-5" id="counter">
              <div class="col-md-6 col-12">
                <div class="row">
                  <div class="col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                    <div
                      class="card counter-box border-0 bg-light shadow text-center rounded"
                    >
                      <div class="card-body py-5">
                        <h2 class="mb-0">
                          <countTo
                            :startVal="3"
                            :endVal="20"
                            :duration="10000"
                          ></countTo
                          >%
                        </h2>
                        <h5 class="counter-head mb-0">Конверсия</h5>
                      </div>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-12 mt-4 pt-2">
                    <div
                      class="card counter-box border-0 bg-primary shadow text-center rounded"
                    >
                      <div class="card-body py-5">
                        <h2 class="text-light title-dark mb-0">
                          <countTo
                            :startVal="1"
                            :endVal="15"
                            :duration="10000"
                          ></countTo
                          >K
                        </h2>
                        <h5 class="counter-head mb-0 title-dark text-light">
                          Писем послано
                        </h5>
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                </div>
                <!--end Row-->
              </div>
              <!--end col-->

              <div class="col-md-6 col-12">
                <div class="row pt-lg-4 mt-lg-4">
                  <div class="col-12 mt-4 pt-2">
                    <div
                      class="card counter-box border-0 bg-success shadow text-center rounded"
                    >
                      <div class="card-body py-5">
                        <h2 class="text-light title-dark mb-0">
                          <countTo :startVal="0" :endVal="300" :duration="10000">
                          </countTo
                          >K
                        </h2>
                        <h5 class="counter-head mb-0 title-dark text-light">
                          Резюме
                        </h5>
                      </div>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-12 mt-4 pt-2">
                    <div
                      class="card counter-box border-0 bg-light shadow text-center rounded"
                    >
                      <div class="card-body py-5">
                        <h2 class="mb-0">
                          <countTo
                            :startVal="3"
                            :endVal="5"
                            :duration="5000"
                          ></countTo
                          >+
                        </h2>
                        <h5 class="counter-head mb-0">Интеграций</h5>
                      </div>
                    </div>
                  </div>
                  <!--end col--that
                can provide everything you need to generate awareness, drive
                traffic, connect>
                </div>
                end Row-->
              </div>
              <!--end col-->
            </div>
            <!--end Row-->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div> </div>


      <!--end container-->
    </section>
    <!--end section-->
    <!-- Counter End -->

    <!-- News Start -->
    <!--
    <section class="section bg-light">


      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">Latest News</h4>
              <p class="text-muted para-desc mx-auto mb-0">
                Start working with
                <span class="text-primary font-weight-bold">Landrick</span> that
                can provide everything you need to generate awareness, drive
                traffic, connect.
              </p>
            </div>
          </div>
        </div>


        <News />
      </div>
      -->
      <!--end container-->

      <!--

      <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title">
              <h4 class="title mb-4">
                See everything about your employee at one place.
              </h4>
              <p class="text-muted para-desc mx-auto mb-0">
                Start working with
                <span class="text-primary font-weight-bold">Landrick</span> that
                can provide everything you need to generate awareness, drive
                traffic, connect.
              </p>

              <div class="mt-4">
                <a href="javascript:void(0)" class="btn btn-primary mt-2 mr-2"
                  >Get Started Now</a
                >
                <a
                  href="javascript:void(0)"
                  class="btn btn-outline-primary mt-2"
                  >Free Trial</a
                >
              </div>
            </div>
          </div>

        </div>

      </div>



    </section>
     -->
    <!--end section-->
    <!-- News End -->
    <Footer />

    <!-- Footer End
    <Switcher />
    -->
    <!-- Back to top
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a> -->
    <!-- Back to top -->
  </div>
</template>


